*,
*::after,
*::before
	margin: 0
	padding: 0
	box-sizing: border-box
	
.fallback__wrapper
	top: 0
	left: 0
	position: fixed
	width: 100vw
	height: 100vh
	background: linear-gradient(287.56deg, #450812 0%, #01193E 95.31%)
	display: flex
	align-items: center
	justify-content: center