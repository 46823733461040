.container {
  /* width: 300px; */
  width: 1350px;
  overflow: hidden;
  /* margin: 20px auto; */
  z-index: 1000;
  height: max-content;
}
.container1 {
  /* width: 300px; */
  width: 300px;
  overflow: hidden;
  /* margin: 20px auto; */
  z-index: 1000;
  height: max-content;
}
.container2 {
  /* width: 300px; */
  width: 750px;
  overflow: hidden;
  /* margin: 20px auto; */
  z-index: 1000;
  height: max-content;
}

.scroll-content {
  display: flex;
  transition: transform 9s linear;
}
.linies {
  height: 205px;
  position: absolute;
  width: 2px;
  left: 50%;
  background-color: #ffc700;
  z-index: 10000;
}
/* .item {
  min-width: 190px;
  height: 205px;
  background-color: #3498db;
  margin-right: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  z-index: -1;
} */

.scrolling {
  transition: none;
}
