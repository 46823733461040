@font-face {
	font-family: "Gotham Pro";
	font-weight: 500;
	font-style: normal;
	src: url("./Inter-Thin.ttf");
}
@font-face {
	font-family: "Gotham Pro";
	font-weight: 300;
	font-style: normal;
	src: url("./Inter-Light.ttf");
}
@font-face {
	font-family: "Gotham Pro";
	font-weight: 500;
	font-style: normal;
	src: url("./Inter-Medium.ttf");
}
@font-face {
	font-family: "Gotham Pro";
	font-weight: 700;
	font-style: normal;
	src: url("./Inter-Bold.ttf");
}
@font-face {
	font-family: "Gotham Pro";
	font-weight: 800;
	font-style: normal;
	src: url("./Inter-Black.ttf");
}
